import React from 'react';
import { AdminProvider } from '../../../context/Admin/AdminContext';
import Authenticate from '../../../components/Identity/Authenticate';
import AuthorizationWrapper from '../../../components/Admin/AuthorizationWrapper';
import CancelledSubscriptionView from '../../../components/Admin/Accounts/CancelledSubscriptionView';

const Cancelled = () => (
  <AdminProvider>
    <AuthorizationWrapper>
      <CancelledSubscriptionView />
    </AuthorizationWrapper>
  </AdminProvider>
);

export default Authenticate(Cancelled);
