import React, { useContext, useEffect, useState } from 'react';
import { Progress, Spin } from 'antd';
import dayjs from 'dayjs';
import { DataStore } from 'aws-amplify';
import { navigate } from 'gatsby';
import HeaderComponent from '../Header';
import SidebarComponent from '../Sidebar';
import * as styles from '../../../styles/admin/CancelledSubscriptionView.module.scss';
import AdminContext from '../../../context/Admin/AdminContext';
import {
  EnterpriseProfile, EnterpriseSubscriptionStatus, Role,
} from '../../../models';
import { fetchMetrics } from '../Services/MetricsApiService';
import { reactivateSubscription } from '../Services/ChargebeeService';
import {
  toaster, isOnFreeTrialPlan, isEligibleToManageBilling, postEvent, getCurrentPlanItem, isBDMUser,
} from '../../../services/utils';
import { NavigationRoute, navigateTo } from '../Services/NavigationService';

interface EnterpriseUsageStats {
  data: {
    attributes: {
      tasksStarted: number,
      licensesUsed: number,
    },
    id: string,
    type: 'enterpriseusagestats',
  }
}

const navigateToBilling = () => { navigateTo(NavigationRoute.BILLING_PAGE); };

const CancelledSubscriptionView: React.FC = () => {
  const { profileInfo, companyInfo } = useContext(AdminContext);
  const [isEligibleToReactivate, setIsEligibleToReactivate] = useState(false);
  const [loader, setLoader] = useState(false);

  const isOwner = profileInfo?.roles?.includes(Role.OWNER);

  const subscriptionExpiryDate = dayjs(companyInfo?.subscription?.currentTermEnd).format('DD MMMM YYYY');

  const showBillingLink = isEligibleToManageBilling(
    companyInfo?.subscription, profileInfo?.roles,
  );

  const billingText = isOnFreeTrialPlan(companyInfo?.subscription) ? 'Upgrade' : 'Manage Billing';

  const triggerIdentifyEvent = (updatedCompanyProfile: any) => {
    const { subscription } = updatedCompanyProfile;
    const currentPlanItem = getCurrentPlanItem(subscription);
    const licenseCount = currentPlanItem.quantity - currentPlanItem.freeQuantity;
    const eventPayload = {
      userId: profileInfo?.id,
      enterpriseId: updatedCompanyProfile.id,
      originalTimestamp: dayjs().toISOString(),
      sentAt: dayjs().toISOString(),
      traits: {
        email: profileInfo?.email,
        firstName: profileInfo?.firstName,
        lastName: profileInfo?.lastName,
        name: profileInfo?.name,
        roles: profileInfo?.roles,
        enterpriseSubStatus: subscription.status,
        enterprisePlan: currentPlanItem.itemPriceId,
        enterpriseLicenseCount: licenseCount,
        lastActive: dayjs().toISOString(),
        exclude: isBDMUser(profileInfo?.email),
      },
    };
    postEvent(eventPayload, '/identify');
  };

  async function fetchCourseRegistrationStats() {
    try {
      const path = `/enterpriseusagestats/${companyInfo.id}`;
      const response: EnterpriseUsageStats = await fetchMetrics(path, {});
      if (response?.data) {
        const tasksStarted = response.data.attributes.tasksStarted || 0;
        const isFreeTrial = isOnFreeTrialPlan(companyInfo?.subscription);
        setIsEligibleToReactivate(isFreeTrial && tasksStarted <= 10);
      }
    } catch (e) {
      console.log(e);
    }
  }

  async function reactivate() {
    try {
      setLoader(true);
      await reactivateSubscription({
        enterpriseId: companyInfo?.id || '',
        subId: companyInfo?.subscription?.id || '',
      });
    } catch (err: any) {
      const errorMessage = err?.response?.data?.errors?.[0]?.title || 'Something went wrong';
      toaster(errorMessage, 'error');
      setLoader(false);
    }
  }

  async function pageValidation() {
    if (companyInfo?.subscription?.status !== EnterpriseSubscriptionStatus.CANCELLED) {
      navigate('/');
    }
  }

  useEffect(() => {
    if (companyInfo?.id && profileInfo?.id) {
      pageValidation();
      fetchCourseRegistrationStats();
      const subscription = DataStore
        .observe(EnterpriseProfile, (profile) => profile.id('eq', companyInfo.id))
        .subscribe((msg) => {
          if (msg.element?.subscription?.status === EnterpriseSubscriptionStatus.NON_RENEWING) {
            triggerIdentifyEvent(msg.element);
            navigate('/admin/');
          }
        });
      return () => {
        subscription.unsubscribe();
      };
    }
    return () => { };
  }, [companyInfo, profileInfo]);

  return (
    <div className={styles.cancelledSubscriptionViewWrapper}>
      <HeaderComponent />
      <div className={styles.cancelledSubscriptionViewContainer}>
        <SidebarComponent />
        <div className={styles.cancelledSubscriptionViewContent}>
          <div className={styles.subscriptionExpiryWrapper}>
            <div className={styles.titleWrapper}>
              <p className={styles.titleDescription}>Welcome to your admin center,</p>
              <h2>{companyInfo?.name}</h2>
            </div>
            <Progress
              type="circle"
              width={80}
              status="exception"
              format={() => (
                <>
                  <h1>0</h1>
                  <p>Days left</p>
                </>
              )}
              percent={1}
            />
            <p className={styles.accountExpiry}>
              Your account expired on
              {' '}
              <span>{subscriptionExpiryDate}</span>
              .
            </p>
            <p className={styles.contactUs}>
              Continue with your course without any interruptions by upgrading your
              account to an Enterprise Plan. If you need more time to evaluate our platform
              please don&apos;t hesitate to reach out.
            </p>
            {isOwner && (
              <div className={styles.ctaBlock}>
                {isEligibleToReactivate && (
                  <div className={styles.buttonWrapper}>
                    <button id="admin-accounts-cancelled-restart-trial" type="button" onClick={reactivate} disabled={loader}>
                      Restart Trial
                    </button>
                    {loader && <Spin />}
                  </div>
                )}
                {
                  showBillingLink
                  && (
                    <div className={styles.buttonWrapper}>
                      <button
                        id="admin-accounts-cancelled-billing"
                        type="button"
                        onClick={navigateToBilling}
                        disabled={loader}
                      >
                        {billingText}
                      </button>
                    </div>
                  )
                }
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CancelledSubscriptionView;
