// extracted by mini-css-extract-plugin
export var cancelledSubscriptionViewWrapper = "CancelledSubscriptionView-module--cancelledSubscriptionViewWrapper--2Xy9J";
export var cancelledSubscriptionViewContainer = "CancelledSubscriptionView-module--cancelledSubscriptionViewContainer--2613F";
export var cancelledSubscriptionViewContent = "CancelledSubscriptionView-module--cancelledSubscriptionViewContent--x3JkO";
export var subscriptionExpiryWrapper = "CancelledSubscriptionView-module--subscriptionExpiryWrapper--UZFSS";
export var titleWrapper = "CancelledSubscriptionView-module--titleWrapper--34bJM";
export var titleDescription = "CancelledSubscriptionView-module--titleDescription--35CaE";
export var accountExpiry = "CancelledSubscriptionView-module--accountExpiry--2Fnff";
export var contactUs = "CancelledSubscriptionView-module--contactUs--yyj4D";
export var contactUsCta = "CancelledSubscriptionView-module--contactUsCta--1wjYg";
export var chatBoxIcon = "CancelledSubscriptionView-module--chatBoxIcon--3ECSy";
export var ctaBlock = "CancelledSubscriptionView-module--ctaBlock--3W1yx";
export var buttonWrapper = "CancelledSubscriptionView-module--buttonWrapper--3UlEM";